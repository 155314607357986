import React from "react"
import Layout from "../components/Layout"

export default () => (
  <Layout>
    <div>
      <h1><center>404 - Sivua ei löytynyt</center></h1>
    </div>
  </Layout>
)
